import { ref } from 'vue';
import SuperTokens from 'supertokens-web-js';
import ThirdParty from 'supertokens-web-js/recipe/thirdparty';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';
import Passwordless from 'supertokens-web-js/recipe/passwordless';
import Session from 'supertokens-web-js/recipe/session';
import { apiDomain } from './useConstants';
import { getUserData } from '../api/user';

SuperTokens.init({
  appInfo: {
    appName: 'Aristeem',
    apiDomain
  },
  recipeList: [
    EmailPassword.init(),
    ThirdParty.init(),
    Passwordless.init(),
    Session.init()
  ]
});

const user = ref(null);
const isRequested = ref(false);

function doesSessionExist() {
  return Session.doesSessionExist();
}

async function signIn(email, password) {
  let response;
  try {
    response = await EmailPassword.signIn({
      formFields: [
        {
          id: 'email',
          value: email
        },
        {
          id: 'password',
          value: password
        }
      ]
    });
  } catch (error) {
    return { success: false, error: error.message };
  }

  if (response?.status === 'OK') {
    isRequested.value = false;
    await getUserAsync();
    return { success: true };
  }

  // known statuses are: OK, WRONG_CREDENTIALS_ERROR, FIELD_ERROR
  return { success: false, error: response?.status };
}

async function signOut() {
  user.value = null;
  await Session.signOut();
}

// NOTE: possibly we should check email for all strategies (email, google, facebook, passwordless)
// https://supertokens.com/docs/passwordless/common-customizations/get-user-info
async function doesEmailExist(email) {
  let response;
  try {
    response = await EmailPassword.doesEmailExist(email);
  } catch (error) {
    return { success: false, error: error.message };
  }

  if (response.status === 'OK') {
    return { success: true, doesExist: response.doesExist };
  }

  return { success: false, error: 'UNKNOWN_RESPONSE' };
}

async function getUserAsync() {
  if (isRequested.value) {
    return user;
  }

  isRequested.value = true;

  const sessionExists = await doesSessionExist();
  if (!sessionExists) {
    return user;
  }

  try {
    const { data, statusCode } = await getUserData();

    if (statusCode !== 200) {
      return user;
    }

    setUser(data);

  } catch (error) {
    console.error('Error getting User', error);
  }

  return user;
}

function getUser() {
  if (!isRequested.value) {
    getUserAsync();
  }

  return user;
}

function setUser(newUser) {
  user.value = { ...newUser };
}

async function signUp(email) {
  let response;
  try {
    response = await Passwordless.createCode({ email });

  } catch (error) {
    return { success: false, error: error.message };
  }

  if (response?.status === "SIGN_IN_UP_NOT_ALLOWED") {
    return { success: false, error: response.reason };
  }

  return { success: true };
}

async function resendMagicLink() {
  try {
    const response = await Passwordless.resendCode();

    if (response.status === "RESTART_FLOW_ERROR") {
      await Passwordless.clearLoginAttemptInfo();
      return false;
    }

    return true;
  } catch (err) {
    return false;
  }
}

export {
  getUser,
  getUserAsync,
  doesSessionExist,
  doesEmailExist,
  signIn,
  signOut,
  signUp,
  resendMagicLink
};
