import { showToast } from '../utils/domUtils/showNotification';

export async function customFetch(url, method = 'get', options = {}) {
  try {
    const object = {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...options.headers
      }
    };

    if ([ 'post', 'put', 'patch', 'delete' ].includes(method)) {
      object.body = JSON.stringify(options.body) || '{}';
    }

    const response = await fetch(url, object);

    if (!response.ok) {
      if (options.onError) {
        return options.onError(response);
      }

      if (response.status === 404) {
        console.log('404 error, redirecting to 404 page');
        window.location.href = '/404';
        return { statusCode: response.status };
      }

      showToast('Error', response.statusText, 'error');
      return { statusCode: response.status };
    }
    const data = await response.json();
    return { data, statusCode: response.status };

  } catch (error) {
    if (options.onError) {
      return options.onError(error);
    }

    console.error('Error API request:', error);
    showToast('Error', 'Error API request', 'error');
    return { error };
  }
}
