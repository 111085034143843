import { apiDomain } from '../composables/useConstants';
import { customFetch } from '../composables/useCustomFetch';

export async function getUserData(options = {}) {
  return await customFetch(`${apiDomain}/api/user`, 'get', options);
}

export async function postAddProgramById(id, options = {}) {
  return await customFetch(`${apiDomain}/api/user/program/${id}`, 'post', options);
}

export async function deleteProgramById(id, options = {}) {
  return await customFetch(`${apiDomain}/api/user/program/${id}`, 'delete', options);
}
