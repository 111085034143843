export const apiDomain = import.meta.env.VITE_APP_API_URL;

export const websocketDomain = apiDomain.replace('https://', 'wss://').replace('http://', 'ws://');

export const TEMPORARY_DISABLED = true;

export const STATE_LOGIN_INITIAL = 0;
export const STATE_LOGIN_INITIAL_LOADING = 10; // IT'S GOOD IDEA TO ADD NEW STATES ID?

export const STATE_LOGIN_WELCOME = 1;
export const STATE_LOGIN_WELCOME_LOADING = 11; // IT'S GOOD IDEA TO ADD NEW STATES ID?

export const STATE_LOGIN_EMAIL_CHECKING = 2;
export const STATE_LOGIN_EMAIL_SUCCESS = 3;
export const STATE_LOGIN_EMAIL_FAIL = 4;
export const STATE_LOGIN_EMAIL_RESEND = 5;
export const STATE_LOGIN_PASSWORD_ASK = 6;
export const STATE_LOGIN_PASSWORD_CHECKING = 7;
export const STATE_LOGIN_PASSWORD_SUCCESS = 8;
export const STATE_LOGIN_PASSWORD_FAIL = 9;

export const VIDEO_ENGINEERS_CATEGORIES_IDS = [ 1 ];
export const GRAPHIC_CATEGORIES_IDS = [ 2 ];
export const THREE_D_CATEGORIES_IDS = [ 3 ];
export const ARCHiTECTS_ENGINEERS_IDS = [ 4 ];

export const programSlides = [
  {
    title: 'Selected for designers',
    description: 'Photoshop, Illustrator, After Effects and other programs that perfectly suits for designers ',
    imgUrl: 'https://cdn.aristeem.com/images/3D+max/2.png',
    videoUrl: 'https://d9usr6i0h5fem.cloudfront.net/public/designer.mp4',
    to: `/category/${GRAPHIC_CATEGORIES_IDS[0]}`
  },
  {
    title: 'Selected for 3d artists',
    description: 'Cinema 4D, Blender, and other programs that perfectly suits for 3d artists',
    imgUrl: 'https://cdn.aristeem.com/images/Adobe+premiere+pro/2.webp',
    videoUrl: 'https://d9usr6i0h5fem.cloudfront.net/public/3dArtist.mp4',
    to: `/category/${THREE_D_CATEGORIES_IDS[0]}`
  },
  {
    title: 'Selected for video makers',
    description: 'Premiere Pro, After Effects and other programs that perfectly suits for video makers',
    imgUrl: 'https://cdn.aristeem.com/images/Adobe+Illustrator+2024/2.png',
    videoUrl: 'https://d9usr6i0h5fem.cloudfront.net/public/videoMaker.mp4',
    to: `/category/${VIDEO_ENGINEERS_CATEGORIES_IDS[0]}`
  }
];

export const FAST_CATEGORY_FILTERS = [
  { label: 'All', ids: [ 999 ] },
  { label: 'Video engineers', ids: VIDEO_ENGINEERS_CATEGORIES_IDS },
  { label: 'Graphic', ids: GRAPHIC_CATEGORIES_IDS },
  { label: '3D', ids: THREE_D_CATEGORIES_IDS },
  { label: 'Architects & Engineers', ids: ARCHiTECTS_ENGINEERS_IDS }
];

export const licenseTypes = [
  { name: 'All', value: 'all' },
  { name: 'Free', value: 'free' },
  { name: 'Paid', value: 'paid' }
];

export const MAX_SIZE_UPLOAD_FILE = 5 * 1024 * 1024;
export const ALLOWED_FORMATS_TO_UPLOAD = [ 'image/png', 'image/jpeg', 'image/webp' ];
export const AUTH_DIGITS_COUNT = 6;
export const DELETE_ACCOUNT_REASONS = [
  {
    title: 'I have negative service experience',
    value: 'I have negative service experience'
  },
  {
    title: 'I found alternative',
    value: 'I found alternative'
  },
  {
    title: 'Your service is too expensive',
    value: 'Your service is too expensive'
  },
  {
    title: 'I can’t find a program that I would like to',
    value: 'I can’t find a program that I would like to'
  },
  {
    title: 'Other',
    value: 'Other'
  }
];

export const IMAGE_SIZES = [ 280, 560, 840, 1120, 1400, 1680 ];

export const LANDING_PAGE = 'https://aristeem.com';
