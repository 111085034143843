import { createRouter, createWebHistory } from 'vue-router';
import { signOut, doesSessionExist } from '../composables/useAuth.js';

let authorizeResult = null;

async function isAuthorized() {
  if (authorizeResult === null) {
    const _isAuthorized = await doesSessionExist();
    authorizeResult = { isAuthorized: _isAuthorized };
  }

  return authorizeResult.isAuthorized;
}

const routes = [
  {
    path: '/verify',
    component: () => import('../pages/VerifyPage.vue')
  },
  {
    path: '/auth-callback/google',
    component: () => import('../pages/AuthCallback.vue')
  },
  {
    path: '/auth-callback/apple',
    component: () => import('../pages/AuthCallback.vue')
  },
  {
    path: '/auth-callback/facebook',
    component: () => import('../pages/AuthCallback.vue')
  },
  {
    path: '/programs/',
    name: 'Programs',
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next({ name: 'PrivatePrograms' });
      } else {
        next({ name: 'PublicPrograms' });
      }
    }
  },
  {
    path: '/programs/:programId',
    name: 'Program',
    props: true,
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next({ name: 'PrivateProgram', params: _to.params });
      } else {
        next({ name: 'PublicProgram', params: _to.params });
      }
    }
  },

  {
    path: '/license-type/:licenseType',
    name: 'License',
    props: true,
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next({ name: 'PrivateLicenseType', params: _to.params });
      } else {
        next({ name: 'PublicLicenseType', params: _to.params });
      }
    }
  },

  {
    path: '/publisher/:publisherId',
    name: 'Publisher',
    props: true,
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next({ name: 'PrivatePublisher', params: _to.params });
      } else {
        next({ name: 'PublicPublisher', params: _to.params });
      }
    }
  },

  {
    path: '/developer/:developerId',
    name: 'Developer',
    props: true,
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next({ name: 'PrivateDeveloper', params: _to.params });
      } else {
        next({ name: 'PublicDeveloper', params: _to.params });
      }
    }
  },

  {
    path: '/category/:categoryId',
    name: 'Category',
    props: true,
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next({ name: 'PrivateCategory', params: _to.params });
      } else {
        next({ name: 'PublicCategory', params: _to.params });
      }
    }
  },

  {
    path: '/',
    component: () => import('../pages/MainPage.vue'),
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next('/user');
      } else {
        next();
      }
    },
    children: [
      {
        path: '',
        component: () => import('../pages/LibraryPage.vue')
      },
      {
        path: '/features',
        component: () => import('../pages/FeaturesPage.vue')
      },
      {
        path: '/pricing',
        component: () => import('../pages/PricingPage.vue')
      },
      {
        path: '/company',
        component: () => import('../pages/CompanyPage.vue')
      },
      {
        path: '/programs',
        name: 'PublicPrograms',
        component: () => import('../pages/LibraryPage.vue')
      },
      {
        path: '/programs/:programId',
        name: 'PublicProgram',
        component: () => import('../pages/ProgramPage.vue'),
        props: true
      },
      {
        path: '/license-type/:licenseType',
        name: 'PublicLicenseType',
        component: () => import('../pages/EntityPage.vue'),
        props: route => ({ attribute: route.params.licenseType, type: 'License type', statsField: route.params.licenseType })
      },
      {
        path: '/publisher/:publisherId',
        name: 'PublicPublisher',
        component: () => import('../pages/EntityPage.vue'),
        props: route => ({ attribute: route.params.publisherId, type: 'Publisher', statsField: 'publishers' })
      },
      {
        path: '/developer/:developerId',
        name: 'PublicDeveloper',
        component: () => import('../pages/EntityPage.vue'),
        props: route => ({ attribute: route.params.developerId, type: 'Developer', statsField: 'developers' })
      },
      {
        path: '/category/:categoryId',
        name: 'PublicCategory',
        component: () => import('../pages/EntityPage.vue'),
        props: route => ({ attribute: route.params.categoryId, type: 'Category', statsField: 'categories' })
      }
    ]
  },

  {
    path: '/user',
    component: () => import('../pages/LoggedUserPage.vue'),
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next();
      } else {
        next('/login');
      }
    },
    children: [
      {
        path: '',
        redirect: '/library'
      },
      {
        path: '/library',
        component: () => import('../pages/LibraryPage.vue')
      },
      {
        path: '/programs',
        name: 'PrivatePrograms',
        component: () => import('../pages/ProgramsPage.vue')
      },
      {
        path: '/programs/:programId',
        name: 'PrivateProgram',
        component: () => import('../pages/ProgramPage.vue'),
        props: true
      },
      {
        path: '/license-type/:licenseType',
        name: 'PrivateLicenseType',
        component: () => import('../pages/EntityPage.vue'),
        props: route => ({ attribute: route.params.licenseType, type: 'License type', statsField: route.params.licenseType })
      },
      {
        path: '/publisher/:publisherId',
        name: 'PrivatePublisher',
        component: () => import('../pages/EntityPage.vue'),
        props: route => ({ attribute: route.params.publisherId, type: 'Publisher', statsField: 'publishers' })
      },
      {
        path: '/developer/:developerId',
        name: 'PrivateDeveloper',
        component: () => import('../pages/EntityPage.vue'),
        props: route => ({ attribute: route.params.developerId, type: 'Developer', statsField: 'developers' })
      },
      {
        path: '/category/:categoryId',
        name: 'PrivateCategory',
        component: () => import('../pages/EntityPage.vue'),
        props: route => ({ attribute: route.params.categoryId, type: 'Category', statsField: 'categories' })
      },
      {
        path: '/files',
        name: 'PrivateFiles',
        component: () => import('../pages/FilesPage.vue')
      }
      // TEMPORARY HIDDEN
      // {
      //   path: '/my-profile',
      //   name: 'PrivateMyProfile',
      //   component: () => import('../pages/MyProfile.vue')
      // }
      // TEMPORARY HIDDEN
    ]
  },
  {
    path: '/login',
    component: () => import('../pages/LoginPage.vue'),
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next('/user');
      } else {
        next();
      }
    }
  },
  {
    path: '/welcome',
    component: () => import('../pages/LoginPage.vue'),
    props: { isWelcome: true },
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next('/user');
      } else {
        next();
      }
    }
  },
  {
    path: '/logout',
    component: true, // NOTE: this is a hack to make the beforeEnter hook work
    beforeEnter: async () => {
      await signOut();
      router.push('/');
    }
  },
  {
    path: '/terms',
    component: () => import('../pages/TermsPage.vue')
  },

  {
    path: '/404',
    name: 'NotFound',
    component: () => import('../pages/NotFoundPage.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../pages/NotFoundPage.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    const element = document.querySelector('main');
    if (element) {
      element.scrollTo(0, 0);
      return null;
    }

    return { top: 0 };
  }
});

router.afterEach(() => {
  // reset the authorization check
  authorizeResult = null;
});

export default router;
